import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/gaestebuch/admin/:token',
    name: 'gaestebuchAdmin',
    component: () => import('../views/TheGuestbook.vue')
  },
  {
    path: '/gaestebuch',
    name: 'gaestebuch',
    component: () => import('../views/TheGuestbook.vue')
  },
  {
    path: '/agb',
    name: 'agb',
    component: () => import('../views/TheAGBPage.vue')
  },
  {
    path: '/news/:news_id/:news_slug',
    name: 'news',
    component: () => import('../views/TheArticle.vue')
  },
  {
    path: '/page/:news_id/:news_slug',
    name: 'page',
    component: () => import('../views/ThePage.vue')
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: () => import('../views/ThePrivacyPage.vue')
  },
  {
    path: '/bildergalerie/:image_id',
    name: 'ImageViewer',
    props: true,
    component: () => import('../views/TheGallerySingleView.vue')
  },
  {
    path: '/bildergalerie',
    name: 'bildergalerie',
    component: () => import('../views/TheGallery.vue')
  },
  {
    path: '/warenkorb',
    name: 'warenkorb',
    component: () => import('../views/TheShoppingcard.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

let currentHash = '';

router.beforeEach((to, from, next) => {
  if (window.location.hash) {
    currentHash = window.location.hash;
  }
  next();
});

router.afterEach((to, from) => {
  if (currentHash) {
    window.location.hash = currentHash;
  }
});

export default router;
