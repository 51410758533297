<template>
  <div class="
  opener
  h-[60vh]
  md:h-[500px]
">
    <div class="page-wrapper
  h-[60vh]
  md:h-[500px]
">
      <div class="slider flex pt-2">
        <div class="slider-item w-full md:w-1/2 md:flex p-2 items-start">
          <div
              class="slider-image"
              style="background-image: url(https://eisenbahn.wingen.de/eisenbahn_archive/100422/663c6ded13d23.webp)">
            <img src="https://eisenbahn.wingen.de/eisenbahn_archive/98799/663c6ded32b88.webp"
                 class="max-h-[20vh] md:hidden w-full object-scale-down		"
            />
          </div>
          <div class="bg-blue w-full md:w-1/2 md:h-[196px] text-left p-2">
            <div class="text-white font-bold text-lg whitespace-nowrap overflow-hidden text-ellipsis">
              001 211 in Bamberg (1)
            </div>
            <div class="text-white whitespace-nowrap overflow-hidden text-ellipsis">
              001 211 macht sich im Bw Bamberg bereit, den E 459 nach Hof zu übernehmen.
            </div>
            <div class="bg-orange inline-block text-white mt-1 font-bold cursor-pointer py-[2px] px-1">
              zum Bild
            </div>
          </div>
        </div>
        <div class="slider-item w-full md:w-1/2 md:flex p-2 items-start">
          <div
              class="slider-image"
              style="background-image: url(https://eisenbahn.wingen.de/eisenbahn_archive/98799/663c6ded32b88.webp)">
            <img src="https://eisenbahn.wingen.de/eisenbahn_archive/98799/663c6ded32b88.webp"
                 class="mh-[50vh] md:hidden w-full"
            />
          </div>
          <div class="bg-blue w-full md:w-1/2 md:h-[196px] text-left p-2">
            <div class="text-white font-bold text-lg whitespace-nowrap overflow-hidden text-ellipsis">
              001 211 in Bamberg (1)
            </div>
            <div class="text-white whitespace-nowrap overflow-hidden text-ellipsis">
              001 211 macht sich im Bw Bamberg bereit, den E 459 nach Hof zu übernehmen.
            </div>
            <div class="bg-orange inline-block text-white mt-1 font-bold cursor-pointer py-[2px] px-1">
              zum Bild
            </div>
          </div>
        </div>
        <!--div class="slider-item w-1/2 flex p-2 items-start">
          <div
              class="slider-image"
              style="background-image: url(https://eisenbahn.wingen.de/eisenbahn_archive/98799/663c6ded32b88.webp)">
            <img src="https://eisenbahn.wingen.de/eisenbahn_archive/98799/663c6ded32b88.webp"
              class="h-[50vh] md:hidden"
            />
          </div>
          <div class="bg-blue w-1/2 h-[196px] text-left p-2">
            <div class="text-white font-bold text-lg">
              001 211 in Bamberg (1)
            </div>
            <div class="text-white slider-description">
              001 211 macht sich im Bw Bamberg bereit, den E 459 nach Hof zu übernehmen.
            </div>
            <div class="bg-orange inline-block text-white mt-1 font-bold cursor-pointer py-[2px] px-1">
              zum Bild
            </div>
          </div>
        </div-->
      </div>
    </div>
  </div>
  <div class="page-wrapper">
    <div v-if="articles.length">
      <div class="text-2xl text-blue font-bold my-2 text-left">
        Neues von der Eisenbahnstiftung
      </div>
      <div class="md:flex">
        <news-item v-for="article in articles" :key="article.ID" :article="article"></news-item>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import NewsItem from "@/components/home/newsItem.vue";

const articles = ref([]);

const fetchArticles = async () => {
  try {
    const response = await axios.get('https://eisenbahn.wingen.de/news/get-latest-articles.php');
    articles.value = response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
  }
};

onMounted(() => {
  fetchArticles();
});
</script>

<style lang="scss" scoped>
.opener{
  background-image: url("../assets/heroImage.webp");
  background-color: rgb(255 150 0);
  background-position: 50% 50%;
  background-size: cover;
  background-blend-mode: multiply;
}
.slider{
  align-items: center; /* Vertikale Zentrierung */
  justify-content: center; /* Horizontale Zentrierung */
  height: 100%; /* Stellt sicher, dass die Höhe 100% des übergeordneten Elements ist */
}

.slider-image{
  @media (max-width: 767px) {
    background-image: unset !important;
  }
  @apply md:w-[350px] md:h-[196px] bg-cover object-cover;

  @media (min-width: 768px) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  background-color: rgba(255,255,255,0.3);
  backdrop-filter: blur(3px);

  @media only screen and (min-width: 600px) and (max-width: 1400px) {
   width: calc(100vw / 2 - 4rem - 24px);
  }
}

.slider-description{
  @media only screen and (min-width: 600px) and (max-width: 1400px) {
    display: none;
  }
}

@media (max-width: 767px) {
  .slider-item:nth-child(2) {
    display: none;
  }
}

.isPortrait{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(3, 27, 65, 0.5);
  backdrop-filter: blur(3px);
}
</style>
