<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";

const info = ref({
  totalEntries: 0,
  uploadsLast7Days: 0,
  uploadsToday: 0,
  last: {
    date: "",
    thumb: "",
    url: ""
  }
});

const footerNav = ref([]);
const footerLeft = ref(null)
const footerRight = ref(null)

const fetchFooterText = async (id, direction) => {
  try {
    const response = await axios.get(`https://eisenbahn.wingen.de/news/get-page.php?id=${id}`);
    if(direction === 'left'){
      footerLeft.value = response.data;
    }else{
      footerRight.value = response.data;
    }
  } catch (error) {
    errorMessage.value = 'Es tut uns leid, aber wir konnten die von Ihnen aufgerufene Seite nicht finden.';
  }
};


const fetchNavigation = async () => {
  try {
    const response = await axios.get('https://eisenbahn.wingen.de/news/get-menu.php?id=5');
    footerNav.value = response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
  }
};

onMounted(async () => {
  try {
    const response = await axios.get(`https://eisenbahn.wingen.de/api/info.php`);
    info.value = response.data;
  } catch (error) {
    console.error("Fehler beim Laden der Daten: ", error);
  }
  await fetchNavigation();
  await fetchFooterText(34, 'right');
  await fetchFooterText(32,'left');
});
</script>


<template>
  <footer class="float-left w-full">
    <div class="bg-blue p-4 w-full">
      <div class="page-wrapper text-white text-left ">
        <div class="container">
          <div class="stiftung">
            <div v-html="footerLeft?.content"></div>
          </div>
          <div class="
            archive
            mt-2 border-t border-white pt-2
            md:border-0 md:mt-0 md:pt-0
          ">
            <div class="font-bold font-underline text-lg">Unser Bildarchiv:</div>
            <b>Bilder gesamt:</b> {{info?.totalEntries}}<br />
            <b>Upload heute:</b> {{info?.uploadsToday}}<br />
            <b>Letzte 7 Tage:</b> {{info?.uploadsLast7Days}}<br />
            <b>Letzter Upload:</b> {{info?.last?.date}}<br />
            <router-link :to="info.last.url">
              <img :src="info.last.thumb" class="w-[150px] mt-2"/>
            </router-link>
          </div>
          <div class="
            spende
            mt-2 border-t border-white pt-2
            md:border-0 md:mt-0 md:pt-0
          "
          >
            <div v-html="footerRight?.content"></div>
          </div>
        </div>
      </div>
    </div>
    <nav class="bg-orange w-full">
      <div class="p-2 text-left page-wrapper">
        <router-link :to="navItem.url"
                     class="text-white px-2 text-sm uppercase inline-block"
                     v-for="navItem in footerNav" :key="navItem.ID"
        >
          {{navItem.title}}
        </router-link>
      </div>
    </nav>
  </footer>
</template>

<style scoped lang="scss">
.container {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-auto-flow: row;
  grid-template-areas:
    "stiftung archive spende";
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
    "stiftung"
    "archive"
    "spende";
  }
}

.stiftung { grid-area: stiftung; }

.archive { grid-area: archive; }

.spende { grid-area: spende; }
</style>
