<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
  article: Object
})
const formattedDate = computed(() => {
  if (props.article.date) {
    const date = new Date(props.article.date)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Monate sind 0-indexiert
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }
  return ''
})
const url = computed(() => {
  if(props.article){
    return `/news/${props.article.ID}/${props.article.slug}`
  }
})
</script>

<template>
  <router-link :to="url" class="text-left mb-2 pr-2 block">
    <div class="bg-orange px-1 py-[1px] inline-block font-bold text-xs text-white relative">
      {{formattedDate}}
    </div>
    <div v-html="article.title" class="bg-blue text-white p-2 font-bold"></div>
    <div class="p-1 border">
      <div v-html="article.content_preview"></div>
      <div class="text-orange font-bold cursor-pointer my-2 w-full text-right underline">weiterlesen</div>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
</style>
