import { defineStore } from 'pinia';

export const useShoppingCartStore = defineStore('shoppingCart', {
    // Konfiguriere die Persistenz
    persist: true,

    state: () => ({
        products: []
    }),
    getters: {
        allImageIds: (state) => {
            return state.products.map(product => product.image_id);
        }
    },
    actions: {
        addProduct(product) {
            const existingProduct = this.products.find(p => p.image_id === product.image_id);
            if (!existingProduct) {
                this.products.push(product);
            }
        },
        removeProduct(image_id) {
            console.log('removeProduct')
            console.log(image_id)
            const index = this.products.findIndex(p => p.image_id === image_id);
            console.log(index)
            if (index !== -1) {
                this.products.splice(index, 1);
            }
        },
        removeAllProducts() {
            this.products = []; // Setzt das Array zurück, entfernt alle Produkte
        },
        isProductInCart(image_id) {
            return this.products.some(product => product.image_id === image_id);
        }
    }
});
