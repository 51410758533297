import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import './assets/tailwind.css';
import VueLazyload from 'vue-lazyload';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const app = createApp(App);

// Erstelle und konfiguriere Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Registriere die Plugins
app.use(pinia); // Nutze die bereits erstellte und konfigurierte Pinia-Instanz
app.use(router);
app.use(VueAxios, axios);
app.use(VueLazyload, {
    preLoad: 1.3,
    error: '/lazyloading-error.webp',  // Pfad zum Error-Bild
    loading: '/lazyloading.webp',  // Pfad zum Lade-Bild
    attempt: 2
});

app.mount('#app');
