<script setup>
import {computed, onMounted, ref} from 'vue';
import { useShoppingCartStore } from '@/stores/shoppingCartStore';
import axios from "axios";
import NewsItem from "@/components/home/newsItem.vue";
const shoppingCart = useShoppingCartStore();
const productCount = computed(() => shoppingCart.products.length);

const subNav = ref([]);
const fetchNavigation = async () => {
  try {
    const response = await axios.get('https://eisenbahn.wingen.de/news/get-menu.php?id=4');
    subNav.value = response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
  }
};

onMounted(() => {
  fetchNavigation();
});

</script>

<template>
  <header class="bg-orange">
    <div class="page-wrapper">
      <div class="py-2 text-right max-sm:hidden">
        <router-link :to="navItem.url"
                     class="text-white inline-block pl-2 text-sm uppercase pr-2-[:nth-of-type(1)]"
                     v-for="navItem in subNav" :key="navItem.ID"
        >
          {{navItem.title}}
        </router-link>
      </div>
    </div>
    <nav class="bg-blue md:py-4 md:h-[80px] w-full pt-3 pb-2">
      <div class="page-wrapper">
        <div class="md:float-left">
          <router-link to="/" class="inline-block align-middle">
            <img src="../assets/logo.png"
             class="h-[30px] md:h-[40px]"
              alt="Eisenbahnstiftung"
            />
          </router-link>
          <div class="
          absolute right-0 top-0
          md:static
          inline-block align-middle ml-2 bg-orange px-2 h-[30px] rounded-2xl" v-if="productCount > 0">
            <router-link to="/warenkorb">
              <svg width="20" height="20" fill="#FFF" class="inline-block align-middle">
                <use xlink:href="#shoppingcard" />
              </svg>
              <div class="bg-blue text-white text-sm rounded-full inline-block w-[20px] h-[20px] align-middle">
                {{ productCount }}
              </div>
            </router-link>
          </div>
          <div v-else class="
          absolute right-2 top-2
          md:static
          inline-block align-middle ml-2 border border-white px-2 h-[30px] rounded-2xl">
            <svg width="20" height="20" fill="#FFF" class="inline-block align-middle">
              <use xlink:href="#shoppingcard" />
            </svg>
            <div class="bg-blue text-white text-sm rounded-full inline-block w-[20px] h-[20px] align-middle">
              {{ productCount }}
            </div>
          </div>
        </div>
        <div class="md:float-right mt-2 md:mt-0">
          <router-link class="cursor-pointer text-orange px-2 uppercase inline-block text-xs md:text-sm" to="/">Stiftung</router-link>
          <router-link class="cursor-pointer text-orange uppercase inline-block text-xs md:text-sm" to="/bildergalerie">Bildarchiv</router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped lang="scss">

</style>
